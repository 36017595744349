import React from "react";

interface ImageWidgetProps {
    src: string;
    alt: string;
    show: string;
    url?: string;
}

const LiveImageWidget: React.FC<ImageWidgetProps> = (props) => {
    const withoutLink = (
        <div className="col-span-6 my-2">
            <img src={props.src} alt={props.alt} className="w-full" />
        </div>
    );

    const withLink = (
        <div className="col-span-6 my-2">
            <a href={props.url}>
                <img src={props.src} alt={props.alt} className="w-full" />
            </a>
        </div>
    );

    return props.show === "true" ? (
        props.url === "" ? (
            withoutLink
        ) : (
            withLink
        )
    ) : (
        <span />
    );
};

export default LiveImageWidget;
