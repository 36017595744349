import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import Select from "./Select";
import {SortType} from "interfaces/sort-type.enum";
import {FolderInterface} from "interfaces/folder.interface";
import {translationHelper} from "helpers/translation.helper";
import TextInput from "./TextInput";
import {useConfig} from "../../hooks/use-config.hook";
import {TranslationKeys} from "../../interfaces/translation-keys.enum";

export enum SearchBarFolder {
    EVENTS = "event_folders",
    PRODUCTS = "product_folders",
}

interface SearchBarProps {
    data: any[];
    filter: any;
    available_sorts: {
        label: string;
        type: SortType;
    }[];
    folders: SearchBarFolder;
    folder_ids?: string[];
}

const SearchBar: React.FC<SearchBarProps> = (props) => {
    const config = useConfig();

    const [selectedSorting, setSelectedSorting] = React.useState<SortType>(
        SortType.DEFAULT
    );
    const [search, setSearch] = React.useState("");
    const [selectedFolder, setSelectedFolder] = React.useState("");
    const [folders, setFolders] = React.useState<any>([]);

    const {t, i18n} = useTranslation();

    const {data, filter} = props;

    const filterList = () => {
        let updatedList = data.filter(
            (item: any) =>
                translationHelper(item.title, i18n.language).toLowerCase().search(search) !== -1
        );
        switch (selectedSorting) {
            case SortType.DEFAULT:
                updatedList = updatedList.sort(
                    (a, b) => (a.sort_order || 0) - (b.sort_order || 0)
                );
                break;
            case SortType.CLOSEST_TO_SEARCH:
                updatedList = data.filter(
                    (item: any) =>
                        translationHelper(item.title, i18n.language)
                            .toLowerCase()
                            .search(search) !== -1
                );
                break;
            case SortType.PRICE_ASC:
                updatedList = updatedList.sort((a, b) => a.price - b.price);
                break;
            case SortType.PRICE_DESC:
                updatedList = updatedList.sort((a, b) => b.price - a.price);
                break;
            case SortType.DATE_ASC:
                updatedList = updatedList.sort(
                    (a, b) =>
                        new Date(a.start_date).getTime() -
                        new Date(b.start_date).getTime()
                );
                break;
            case SortType.DATE_DESC:
                updatedList = updatedList.sort(
                    (a, b) =>
                        new Date(b.start_date).getTime() -
                        new Date(a.start_date).getTime()
                );
                break;
            default:
                updatedList = updatedList.sort(
                    (a, b) => (a.sort_order || 0) - (b.sort_order || 0)
                );
                break;
        }

        if (selectedFolder !== "") {
            updatedList = updatedList.filter(
                (item) =>
                    item.folder_ids && item.folder_ids.includes(selectedFolder)
            );
        }

        filter(updatedList);
    };

    useEffect(() => {
        let didCancel = false;

        if (!didCancel) {
            filterList();
        }

        return () => {
            didCancel = true;
        };
    }, [search, selectedSorting, selectedFolder]);

    useEffect(() => {
        buildFolders();
    }, []);

    const buildFolders = () => {
        let unmappedFolders: FolderInterface[] = config[props.folders];

        if (props.folder_ids) {
            unmappedFolders = unmappedFolders.filter(
                (folder) =>
                    props.folder_ids && props.folder_ids.includes(folder.id)
            );
        }

        const tempFolders = [] as any;

        unmappedFolders.forEach((folder: FolderInterface) => {
            if (folder.parent) return;
            if (folder.children.length > 0) {
                tempFolders.push(
                    <optgroup label={translationHelper(folder.title, i18n.language)} key={folder.id}>
                        {folder.children.map((children: FolderInterface) => (
                            <option value={children.id} key={children.id}>
                                {translationHelper(
                                    children.title,
                                    i18n.language
                                )}
                            </option>
                        ))}
                    </optgroup>
                );
                return;
            }
            tempFolders.push(
                <option value={folder.id} key={folder.id}>
                    {translationHelper(folder.title, i18n.language)}
                </option>
            );
        });
        setFolders(tempFolders);
    };

    return (
        <div className="relative w-full">
            <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-y-1 gap-x-4 mb-0 sm:mb-4">
                <div className="col-span-2">
                    <TextInput
                        type="text"
                        placeholder={t("common.search")}
                        onChange={(event) =>
                            setSearch(event.target.value.toLowerCase())
                        }
                    />
                </div>
                <div className="col-span-1">
                    <Select
                        onChange={(event) =>
                            setSelectedSorting(event.target.value as SortType)
                        }
                        value={selectedSorting}
                        margin="mb-0"
                    >
                        {props.available_sorts.map((sort) => (
                            <option value={sort.type} key={sort.type}>
                                {sort.label}
                            </option>
                        ))}
                    </Select>
                </div>
                <div className="col-span-1">
                    <Select
                        onChange={(event) =>
                            setSelectedFolder(event.target.value)
                        }
                        value={selectedFolder}
                        margin="mb-0"
                    >
                        <option value="">{t(TranslationKeys.COMMON_ALL)}</option>
                        {folders}
                    </Select>
                </div>
            </div>
        </div>
    );
};

export default SearchBar;
