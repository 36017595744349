import React, { Fragment, useRef } from "react";
import { OverlayTriggerState, useOverlayTriggerState } from "@react-stately/overlays";
import { ProductInterface } from "interfaces/product.interface";
import TextInput from "components/utils/TextInput";
import Button from "components/utils/button.component";
import { ICartItem } from "interfaces/cart-item.interface";
import { CartService } from "services/cart.service";
import { freezeLive } from "store/actions/live.action";
import { getCookie } from "helpers/cookie.helper";
import { Dialog, Transition } from "@headlessui/react";
import { useAppDispatch } from "../hooks/use-redux.hook";

interface CustomerDetailsModalProps {
    modalState: OverlayTriggerState;
    setSeat: (seat: string) => void;
    setEmail: (email: string) => void;
    setPhone: (phone: string) => void;
    createOrder: (product: ProductInterface) => void;
    product: ProductInterface;
}

const CustomerDetailsModal: React.FC<CustomerDetailsModalProps> = (props) => {
    const {
        modalState,
        setSeat,
        setEmail,
        setPhone,
        createOrder,
        product,
    } = props;

    const dispatch = useAppDispatch();
    const inputRef = useRef(null);

    const close = () => {
        dispatch(freezeLive(false));
        modalState.close();
    };

    return (
        <Transition.Root show={modalState.isOpen} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={inputRef}
                open={modalState.isOpen}
                onClose={close}
            >
                <div className="flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-40 transition-opacity" />
                    </Transition.Child>
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6 mt-8">
                            <form
                                onSubmit={async (event) => {
                                    event.preventDefault();
                                    await createOrder(product);
                                }}
                            >
                                <div className="sm:flex sm:items-start">
                                    <div className="text-left w-full">
                                        <h3
                                            className="text-lg leading-6 font-medium text-gray-900"
                                            id="modal-headline"
                                        >
                                            Asiakastiedot
                                        </h3>
                                        <div className="mt-4">
                                            <div className="mb-3">
                                                <TextInput
                                                    required={false}
                                                    onChange={(event) =>
                                                        setSeat(
                                                            event.target.value,
                                                        )
                                                    }
                                                    label="Katsomo, rivi ja paikkanumero"
                                                    type="text"
                                                    ref={inputRef}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <TextInput
                                                    required={true}
                                                    onChange={(event) =>
                                                        setEmail(
                                                            event.target.value,
                                                        )
                                                    }
                                                    label="Sähköpostiosoite"
                                                    type="email"
                                                />
                                            </div>
                                            <TextInput
                                                required={true}
                                                onChange={(event) =>
                                                    setPhone(event.target.value)
                                                }
                                                label="Puhelinnumero"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="submit"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    >
                                        Jatka
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                        onClick={close}
                                    >
                                        Peruuta
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

const LoadingModal: React.FC<{ modalState: OverlayTriggerState }> = (props) => {
    const { modalState } = props;

    const ref = useRef(null);

    return (
        <Transition.Root show={modalState.isOpen} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                open={modalState.isOpen}
                initialFocus={ref}
                onClose={modalState.close}
            >
                <div className="flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-40 transition-opacity" />
                    </Transition.Child>
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                            <div>
                                <div className="text-center" ref={ref}>
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg leading-6 font-medium text-gray-900"
                                    >
                                        Luodaan tilausta... Odota hetki
                                    </Dialog.Title>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

interface FastOrderClickButtonProps {
    product: ProductInterface;
    amount?: number;
    label: string;
    fullWidth?: boolean;
}

const FastOrderClickButton: React.FC<FastOrderClickButtonProps> = (props) => {
    const { product, label, fullWidth, amount } = props;

    const modalState = useOverlayTriggerState({});
    const loadingModalState = useOverlayTriggerState({});

    const cartService = new CartService();

    const dispatch = useAppDispatch();

    const [selectedProduct, setSelectedProduct] = React.useState<ProductInterface | undefined>(undefined);

    const [seat, setSeat] = React.useState(getCookie("cart_seat") || "");
    const [email, setEmail] = React.useState(getCookie("cart_email") || "");
    const [phone, setPhone] = React.useState(getCookie("cart_phone") || "");

    const onButtonClick = async (product: ProductInterface) => {
        //console.log(getCookie("cart_seat"), getCookie("cart_email"), getCookie("cart_phone"))
        setSelectedProduct(product);
        if (seat === "" || email === "" || phone === "") {
            dispatch(freezeLive(true));
            modalState.open();
            return;
        }
        await createOrder(product);
    };

    const createOrder = async (product: ProductInterface) => {
        //document.cookie = `cart_email=${email};`
        //document.cookie = `cart_phone=${phone};`
        //document.cookie = `cart_seat=${seat};`
        loadingModalState.open();
        const item: ICartItem = {
            id: product.id,
            amount: amount || 1,
            customer_remarks: `Paikka: ${seat}`,
            price: product.price,
            product: product,
        };
        await cartService.validateCustomerDetails({ email, phone });
        await cartService.createQuickOrder(
            { email, phone: cartService.modifyPhoneNumber(phone) },
            item,
        );

        dispatch(freezeLive(false));
    };

    return (
        <>
            <CustomerDetailsModal
                modalState={modalState}
                setEmail={setEmail}
                setPhone={setPhone}
                setSeat={setSeat}
                createOrder={createOrder}
                product={selectedProduct || ({} as ProductInterface)}
            />
            <LoadingModal modalState={loadingModalState} />
            <Button
                color="primary"
                type="button"
                fullWidth={fullWidth}
                onClick={async () => {
                    await onButtonClick(product);
                }}
            >
                {label}
            </Button>
        </>
    );
};

export default FastOrderClickButton;
