import React, { useEffect } from "react";
import { WidgetInterface, Widgets } from "widgets/index";
import moment from "moment";

interface WrapperWidgetProps {
    widgets: WidgetInterface[];
    show_at?: Date;
    hide_at?: Date;
}

const WrapperWidget: React.FC<WrapperWidgetProps> = (props) => {
    const { widgets, show_at, hide_at } = props;

    const [show, setShow] = React.useState(false);

    const WidgetBuilder = (props: WidgetInterface) => {
        const Widget = Widgets[props.type];
        return Widget ? (
            <Widget {...props.data} />
        ) : (
            <p>Virhe tapahtui komponenttia ladatessa! (Virhekoodi: COMP-1)</p>
        );
    };

    const builtWidgets: any = [];

    const buildWidgets = () => {
        widgets.map((widget, i) =>
            builtWidgets.push(
                <>
                    <WidgetBuilder
                        type={widget.type}
                        data={widget.data}
                        key={i}
                    />
                    <div className="mb-4" />
                </>
            )
        );
    };

    buildWidgets();

    useEffect(() => {
        if (!show_at || !hide_at) {
            setShow(true);
            return;
        }
        const checkShow = () => {
            const date = moment(new Date());
            if (
                date.isAfter(moment(show_at)) &&
                date.isBefore(moment(hide_at))
            ) {
                setShow(true);
            } else {
                setShow(false);
            }
        };
        checkShow();
        const timer = setInterval(checkShow, 10000);
        return () => clearTimeout(timer);
    }, [show_at, hide_at]);

    return show ? builtWidgets : <span />;
};

export default WrapperWidget;
