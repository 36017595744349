import React from "react";
import { FolderInterface } from "interfaces/folder.interface";
import EventFolderCard from "components/events/event-folder-card.component";
import { useConfig } from "../hooks/use-config.hook";

interface EventFolderListingWidgetProps {
    folders?: FolderInterface[];
}

const EventFolderListingWidget: React.FC<EventFolderListingWidgetProps> = (
    props
) => {
    const { folders } = props;

    const config = useConfig();

    let eventFolders: FolderInterface[] = [];

    if (config.event_folders) {
        eventFolders = config.event_folders.sort(
            (a, b) => (a.sort_order || 0) - (b.sort_order || 0)
        );
    }

    if (folders) {
        eventFolders = folders.sort(
            (a, b) => (a.sort_order || 0) - (b.sort_order || 0)
        );
    }

    return (
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-4 px-4 py-4 sm:p-0">
            {eventFolders.map((folder: FolderInterface) => (
                <EventFolderCard folder={folder} key={folder.id} />
            ))}
        </div>
    );
};

export default EventFolderListingWidget;
