import React, { useEffect } from "react";
import { Transition } from "@headlessui/react";
import axios from "axios";
import TextInput from "components/utils/TextInput";
import Button from "components/utils/button.component";
import { freezeLive } from "store/actions/live.action";
import { useAppDispatch } from "../../hooks/use-redux.hook";

enum PollType {
    THUMBS = "thumbs",
    MULTI = "multi",
    FREE_TEXT = "free_text",
}

interface PollWidgetProps {
    id: string;
    title: string;
    type: PollType;
    values?: string[];
    show?: string;
}

const PollWidget: React.FC<PollWidgetProps> = (props) => {
    const { id, title, type, values } = props;

    const [show, setShow] = React.useState(props.show);
    const [freeText, setFreeText] = React.useState("");

    const pollAnswered =
        typeof window === "undefined"
            ? false
            : localStorage.getItem(`poll_answered:${id}`) === "true";

    const [voted, setVoted] = React.useState<boolean>(pollAnswered || false);
    const [, setVotedValue] = React.useState("");

    const dispatch = useAppDispatch();

    const handlePollAction = async (value: string) => {
        localStorage.setItem(`poll_answered:${id}`, "true");
        setVoted(true);
        setVotedValue(value);

        await axios.post(`${process.env.GATSBY_LIVE_API_URL}/poll/answer`, {
            poll_id: id,
            value,
        });

        setTimeout(() => {
            setShow("false");
        }, 5 * 1000);
    };

    useEffect(() => {
        if (localStorage.getItem(`poll_answered:${id}`)) {
            setShow("false");
        }
    }, []);

    const multi = (
        <ul>
            {values &&
            values.map((value) => (
                <li className="border-b border-gray-200" key={value}>
                    <button
                        type="button"
                        onClick={() => handlePollAction(value)}
                        className="w-full block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
                    >
                        <div className="px-4 py-4 flex items-center sm:px-6">
                            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                <div>
                                    <div className="text-sm leading-5 font-medium text-indigo-600">
                                        {value}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </button>
                </li>
            ))}
        </ul>
    );

    const free = (
        <div className="px-4 py-5">
            <form
                onSubmit={async (event) => {
                    event.preventDefault();
                    await handlePollAction(freeText);
                    dispatch(freezeLive(false));
                }}
            >
                <TextInput
                    onFocus={() => dispatch(freezeLive(true))}
                    onBlur={(event) =>
                        event.target.value === ""
                            ? dispatch(freezeLive(false))
                            : null
                    }
                    onChange={(event) => setFreeText(event.target.value)}
                />
                <div className="mt-2" />
                <Button color="primary" type="submit" fullWidth={true}>
                    Lähetä
                </Button>
            </form>
        </div>
    );

    return show !== "false" ? (
        <div className="grid grid-cols-2 bg-white shadow rounded-md col-span-6 my-2">
            <div
                className="col-span-2 flex items-center px-4 border-b border-gray-200 sm:px-6 py-6 leading-6 font-medium text-gray-900">
                {title}
            </div>
            <Transition
                show={!voted}
                enterFrom={"opacity-0"}
                enterTo={"opacity-100"}
                leave={"transition-opacity ease-out duration-500"}
                leaveFrom={"opacity-100"}
                leaveTo={"opacity-0"}
                className="col-span-2"
            >
                {type === PollType.THUMBS && (
                    <ThumbsPoll handlePollAction={handlePollAction} />
                )}
                {type === PollType.MULTI && multi}
                {type === PollType.FREE_TEXT && free}
            </Transition>
            <Transition
                show={voted}
                enterFrom={"opacity-0"}
                enterTo={"opacity-100"}
                enter={"transition-opacity ease-in duration-500"}
                leaveFrom={"opacity-100"}
                leaveTo={"opacity-0"}
                className="col-span-2 flex items-center justify-center h-full py-5 px-4 sm:px-6"
            >
                <span className="text-xl">Kiitos!</span>
            </Transition>
        </div>
    ) : (
        <span />
    );
};

const ThumbsPoll: React.FC<{ handlePollAction: (value: string) => void }> = (
    props,
) => {
    const { handlePollAction } = props;

    return (
        <div className="grid grid-cols-2">
            <button
                type="button"
                className="col-span-1 flex justify-center items-center cursor-pointer py-8 focus:bg-gray-300 hover:bg-gray-300 transition duration-300 border-r border-gray-200 h-full"
                onClick={() => handlePollAction("up")}
            >
                <svg
                    className="w-12 h-12"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                >
                    <path
                        d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                </svg>
            </button>
            <button
                type="button"
                className="col-span-1 flex justify-center items-center cursor-pointer focus:bg-gray-300 hover:bg-gray-300 transition duration-300 py-8"
                onClick={() => handlePollAction("down")}
            >
                <svg
                    className="w-12 h-12"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                >
                    <path
                        d="M18 9.5a1.5 1.5 0 11-3 0v-6a1.5 1.5 0 013 0v6zM14 9.667v-5.43a2 2 0 00-1.105-1.79l-.05-.025A4 4 0 0011.055 2H5.64a2 2 0 00-1.962 1.608l-1.2 6A2 2 0 004.44 12H8v4a2 2 0 002 2 1 1 0 001-1v-.667a4 4 0 01.8-2.4l1.4-1.866a4 4 0 00.8-2.4z" />
                </svg>
            </button>
        </div>
    );
};

export default PollWidget;
