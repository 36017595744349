import React from "react";
import { connect } from "react-redux";
import EventCard from "components/events/event-card.component";
import { EventInterface } from "interfaces/event.interface";
import { AppState } from "store/reducers";

interface EventListingWidgetProps {
    folders?: string[];
    events: EventInterface[];
    isLoaded?: boolean;
    isEmpty?: boolean;
}

const EventListingWidget: React.FC<EventListingWidgetProps> = (props) => {
    return (
        props.events && (
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 px-4 py-4 sm:p-0">
                {props.isLoaded && props.isEmpty && (
                    <p>Virhe tapahtui tapahtumia ladatessa!</p>
                )}
                {props.events.map((event: EventInterface) => (
                    <EventCard event={event} key={event.id} />
                ))}
            </div>
        )
    );
};

const mapStateToProps = (
    state: AppState,
    ownProps: EventListingWidgetProps
) => {
    let events = ownProps.events || state.event.events;

    const folders = ownProps.folders;

    if (folders) {
        events = events.filter((event) =>
            event.folder_ids?.some((folder) => folders.includes(folder))
        );
    }

    return {
        events,
        isLoaded: state.event.isLoaded,
        isEmpty: state.event.isEmpty,
    };
};

export default connect(mapStateToProps)(EventListingWidget);
