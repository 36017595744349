import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ProductInterface, ProductType } from "interfaces/product.interface";
import { AppState } from "store/reducers";
import { translationHelper } from "helpers/translation.helper";
import { useTranslation } from "react-i18next";
import FastOrderClickButton from "widgets/fast-order-click-button.widget";
import Select from "components/utils/Select";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";

interface FastProductOrderComponentProps {
    folders: string[];
    products?: ProductInterface[];
}

const FastProduct: React.FC<{ product: ProductInterface }> = (props) => {
    const { i18n } = useTranslation();
    const [amount, setAmount] = React.useState("0");
    const { product } = props;

    const amounts = [];

    if (product.availability) {
        if (
            product.availability.max === undefined ||
            product.availability.step === undefined ||
            product.availability.min === undefined
        ) {
            return null;
        }
        for (
            let i = product.availability.min;
            i <= product.availability.max;
            i += product.availability.step
        ) {
            amounts.push(
                <option value={i} key={i}>
                    {i}
                </option>
            );
        }
    }

    return (
        <div className="w-full rounded bg-white shadow rounded-md h-full">
            <div className="w-full h-48 flex flex-wrap justify-center content-center py-2">
                <img
                    src={product.images && product.images[0].url}
                    className="max-h-full"
                    alt={translationHelper(product.title, i18n.language)}
                />
            </div>
            <div className="px-6 py-4">
                <div className="flex justify-between">
                    <div
                        className="font-bold text-sm mb-2 title-text"
                        title={translationHelper(product.title, i18n.language)}
                    >
                        {translationHelper(product.title, i18n.language)}
                    </div>
                    <div className="font-bold text-sm mb-2 text-primary-600">
                        {(product.price || 0).toLocaleString("fi-FI", {
                            style: "currency",
                            currency: "EUR",
                        })}
                    </div>
                </div>
                <p className="text-gray-700 text-sm description-text">
                    {translationHelper(product.short_info, i18n.language)}
                </p>
                <Select
                    onChange={(event) => setAmount(event.target.value)}
                    value={amount}
                >
                    {amounts}
                </Select>
                <div className="w-full mt-4">
                    <FastOrderClickButton
                        label="Osta"
                        product={product}
                        fullWidth={true}
                        amount={Number.parseInt(amount)}
                    />
                </div>
            </div>
        </div>
    );
};

const OrdersBar: React.FC = (props) => {
    const path = useLocation().pathname.replace(/\/+$/, "").split("/");
    path.pop();
    const location = path.join("/");
    const [orders, setOrders] = React.useState<string[]>([]);

    useEffect(() => {
        if (typeof window !== "undefined") {
            const localOrders = localStorage.getItem("orders");
            if (!localOrders) return;
            setOrders(JSON.parse(localOrders));
        }
    }, []);

    return orders.length > 0 ? (
        <div className="col-span-1 shadow flex items-center justify-between bg-white rounded-md py-4">
            <div className="flex-1 px-4 py-2 text-sm leading-5 truncate">
                <span className="text-gray-900 font-medium hover:text-gray-600 transition ease-in-out duration-150">
                    Tilauksesi <b>{orders.length}</b>
                </span>
            </div>
            <div className="flex-shrink-0 pr-2">
                <span className="inline-flex rounded-md shadow-sm">
                    <Link
                        to={`${location}/tilaukset`}
                        className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline-primary active:bg-primary-700 transition ease-in-out duration-150"
                    >
                        Avaa
                    </Link>
                </span>
            </div>
        </div>
    ) : (
        <span />
    );
};

const FastProductOrderComponent: React.FC<FastProductOrderComponentProps> = (
    props
) => {
    const { products } = props;

    return (
        <>
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-4 col-span-6">
                <OrdersBar />
                {products &&
                    products.map((product) => (
                        <FastProduct product={product} key={product.id} />
                    ))}
            </div>
        </>
    );
};

const mapStateToProps = (
    state: AppState,
    props: FastProductOrderComponentProps
) => {
    const products = filterProducts(
        props.products ? props.products : state.product.products
    );
    return {
        cart: state.cart,
        products:
            products.filter((product) =>
                product.folder_ids?.some(
                    (folder) => props.folders.indexOf(folder) !== -1
                )
            ) || [],
    };
};

const filterProducts = (products: ProductInterface[]) =>
    products.filter((product) =>
        [
            ProductType.MERCHANDISE,
            ProductType.MEMBERSHIP,
            ProductType.VALUE_VOUCHER,
            ProductType.SERIAL_CARD,
        ].includes(product.type)
    );

export default connect(mapStateToProps)(FastProductOrderComponent);
