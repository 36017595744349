import React from "react";
import ReactPlayer from "react-player";
import { freezeLive } from "../store/actions/live.action";
import { useAppDispatch } from "../hooks/use-redux.hook";

interface VideoWidgetProps {
    url: string;
    show: string;
}

const VideoWidget: React.FC<VideoWidgetProps> = (props) => {
    const dispatch = useAppDispatch();

    return props.show === "true" ? (
        <div className="col-span-6 my-2">
            <div className="aspect-w-16 aspect-h-9">
                <ReactPlayer
                    url={props.url}
                    autoPlay={true}
                    controls={true}
                    width="100%"
                    height="100%"
                    onPlay={() => dispatch(freezeLive(true))}
                    onPause={() => dispatch(freezeLive(false))}
                    onEnded={() => dispatch(freezeLive(false))}
                />
            </div>
        </div>
    ) : (
        <span />
    );
};

export default VideoWidget;
