import React, { useEffect } from "react";
import { LiveFeedPostInterface } from "../../interfaces/live-feed-post.interface";
import axios from "axios";
import { LiveFeedPostAttachmentInterface } from "../../interfaces/live-feed-post-attachment.interface";
import ReactPlayer from "react-player";
import { freezeLive } from "../../store/actions/live.action";
import { useAppDispatch } from "../../hooks/use-redux.hook";

interface FeedWidgetProps {
    eventid: string;
    show: string;
}

const FeedAttachment: React.FC<{
    attachment: LiveFeedPostAttachmentInterface;
}> = (props) => {
    const dispatch = useAppDispatch();

    const { attachment } = props;

    if (attachment.mime_type.startsWith("image/")) {
        return (
            <div className="w-full">
                <img src={attachment.external_url} alt="" />
            </div>
        );
    }

    if (
        attachment.mime_type === "application/x-mpegUR" ||
        attachment.mime_type === "video/mp4"
    ) {
        return (
            <div className="aspect-w-16 aspect-h-9">
                <ReactPlayer
                    url={attachment.external_url}
                    autoPlay={true}
                    controls={true}
                    width="100%"
                    height="100%"
                    onPlay={() => dispatch(freezeLive(true))}
                    onPause={() => dispatch(freezeLive(false))}
                    onEnded={() => dispatch(freezeLive(false))}
                />
            </div>
        );
    }

    return <span />;
};

const FeedWidget: React.FC<FeedWidgetProps> = (props) => {
    const [posts, setPosts] = React.useState<LiveFeedPostInterface[]>([]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get<{
                    posts: LiveFeedPostInterface[];
                }>(
                    `${process.env.GATSBY_LIVE_API_URL}/live-feed/${props.eventid}/posts`,
                );
                setPosts(response.data.posts);
            } catch (error) {
                console.log(error);
            }
        };

        fetchPosts();

        const fetchInterval = setInterval(() => {
            fetchPosts();
        }, 15 * 1000);

        return () => {
            clearInterval(fetchInterval);
        };
    }, [props.eventid]);

    return props.show === "true" ? (
        <div className="bg-white shadow rounded-md p-4 col-span-6">
            <div className="flow-root">
                <ul className="-mb-8">
                    {posts.map((post, index) => (
                        <li key={post.id}>
                            <div className="relative pb-8">
                                {index !== posts.length - 1 ? (
                                    <span
                                        className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                        aria-hidden="true"
                                    />
                                ) : null}
                                <div className="relative flex space-x-3">
                                    <div>
                                        <span
                                            className="h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white">
                                            <svg
                                                className="h-5 w-5 text-white"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="min-w-0 flex-1 flex justify-between space-x-4">
                                        <div className="w-full">
                                            {post.title && (
                                                <p className="text-sm text-gray-900">
                                                    {post.title}
                                                </p>
                                            )}
                                            {post.text && (
                                                <p className="text-sm text-gray-700 whitespace-pre-line">
                                                    {post.text}
                                                </p>
                                            )}
                                            {post.attachments &&
                                            post.attachments.map(
                                                (attachment) => (
                                                    <FeedAttachment
                                                        attachment={
                                                            attachment
                                                        }
                                                        key={attachment.id}
                                                    />
                                                ),
                                            )}
                                        </div>
                                        <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                            {/*<time dateTime="2020-09-20"></time>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    ) : (
        <span />
    );
};

export default FeedWidget;
