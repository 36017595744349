import React from "react";

interface CardWidgetProps {
    title?: string;
    text?: string;
}

const CardWidget: React.FC<CardWidgetProps> = (props) => {
    const { title, text } = props;

    return (
        <div className="bg-white shadow sm:rounded-md">
            <div className="px-4 py-5 sm:p-6">
                {title && (
                    <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                        {title}
                    </h3>
                )}
                {text && (
                    <div className="text-sm leading-5 text-gray-500">
                        <div dangerouslySetInnerHTML={{ __html: text }} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default CardWidget;
