import React, { useEffect } from "react";
import { connect } from "react-redux";
import ProductListingWidget from "./product-listing.widget";
import SearchBar, { SearchBarFolder } from "../components/utils/SearchBar";
import { SortType } from "interfaces/sort-type.enum";
import { ProductInterface } from "interfaces/product.interface";
import { AppState } from "store/reducers";

interface ProductListingWithSearchWidgetProps {
    folders: string[];
    products: ProductInterface[];
}

const ProductListingWithSearchWidget: React.FC<ProductListingWithSearchWidgetProps> = (
    props
) => {
    const { products } = props;
    const [filterData, setFilterData] = React.useState(products);

    useEffect(() => {
        setFilterData(products);
    }, [products]);

    return (
        <div>
            <div className="px-4 pt-2 sm:p-0">
                <SearchBar
                    data={products}
                    filter={setFilterData}
                    available_sorts={[
                        { label: "Oletus", type: SortType.DEFAULT },
                        {
                            label: "Osuvimmat",
                            type: SortType.CLOSEST_TO_SEARCH,
                        },
                        { label: "Nouseva hinta", type: SortType.PRICE_ASC },
                        { label: "Laskeva hinta", type: SortType.PRICE_DESC },
                    ]}
                    folders={SearchBarFolder.PRODUCTS}
                    folder_ids={props.folders}
                />
            </div>
            <ProductListingWidget products={filterData} />
        </div>
    );
};

const mapStateToProps = (
    state: AppState,
    props: ProductListingWithSearchWidgetProps
) => {
    let products: ProductInterface[] = state.product.products;
    if (props.folders) {
        products = products.filter((product) =>
            product.folder_ids?.some(
                (folder) => props.folders.indexOf(folder) !== -1
            )
        );
    }
    return {
        products: products
            .sort()
            .sort(
                (a: ProductInterface, b: ProductInterface) =>
                    (a.sort_order || products.length - 1) -
                    (b.sort_order || products.length - 1)
            ),
    };
};

export default connect(mapStateToProps)(ProductListingWithSearchWidget);
