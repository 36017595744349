import React from "react";

enum SpacerDirection {
    TOP = "t",
    BOTTOM = "b",
    LEFT = "l",
    RIGHT = "r",
    VERTICAL = "x",
    HORIZONTAL = "y",
    ALL = "",
}

interface SpacerWidgetProps {
    space: string;
    type: SpacerDirection;
}
const SpacerWidget: React.FC<SpacerWidgetProps> = (props) => (
    <div className={"m" + props.type + "-" + props.space} />
);

export default SpacerWidget;
