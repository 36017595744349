import React from "react";
import { connect } from "react-redux";
import ProductCard from "components/products/product-card.component";
import { ProductInterface, ProductType } from "interfaces/product.interface";
import { AppState } from "store/reducers";

interface ProductListingWidgetProps {
    products: ProductInterface[];
    isLoaded?: boolean;
    isEmpty?: boolean;
}

const ProductListingWidget: React.FC<ProductListingWidgetProps> = (props) => {
    const { products, isLoaded, isEmpty } = props;

    return (
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-4 px-4 py-4 sm:p-0">
            {!isLoaded &&
                !isEmpty &&
                [...Array(12).keys()].map((number) => {
                    return (
                        <div
                            className="col-span-1 bg-white shadow rounded-md p-4 w-full duration-300"
                            key={"product_placeholder_" + number}
                        >
                            <div className="animate-pulse">
                                <div className="bg-gray-400 h-32 mb-2 w-full rounded" />
                                <div className="space-y-4 py-1">
                                    <div className="h-4 bg-gray-400 rounded w-3/4" />
                                    <div className="space-y-2">
                                        <div className="h-4 bg-gray-400 rounded" />
                                        <div className="h-4 bg-gray-400 rounded w-5/6" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            {isLoaded && isEmpty && <p>Virhe tapahtui tuotteita ladatessa!</p>}
            {products &&
                products.map((product) => (
                    <ProductCard product={product} key={product.id} />
                ))}
        </div>
    );
};

const mapStateToProps = (state: AppState, props: ProductListingWidgetProps) => {
    const stateProducts = state.product.products.sort((a, b) => {
        const sortOrderSort = (a.sort_order || 0) - (b.sort_order || 0);
        if (sortOrderSort) {
            return sortOrderSort;
        }

        const titleA: string = a.title?.fi || "";
        const titleB: string = b.title?.fi || "";
        const sortOrderTitle = titleA.localeCompare(titleB);
        if (sortOrderTitle) {
            return sortOrderTitle;
        }

        const sortOrderPrice = (a.price || 0) - (b.price || 0);
        return sortOrderPrice;
    });

    return {
        products: filterProducts(props.products || stateProducts || []),
        isLoaded: state.product.isLoaded,
        isEmpty: state.product.isEmpty,
    };
};

const filterProducts = (products: ProductInterface[]) =>
    products.filter((product) =>
        [
            ProductType.MERCHANDISE,
            ProductType.MEMBERSHIP,
            ProductType.VALUE_VOUCHER,
            ProductType.SERIAL_CARD,
        ].includes(product.type)
    );

export default connect(mapStateToProps)(ProductListingWidget);
