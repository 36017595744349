import { useTranslation } from "react-i18next";
import React from "react";
import { translationHelper } from "helpers/translation.helper";
import { ProductInterface } from "interfaces/product.interface";
import { Link } from "gatsby";
import { LazyImage } from "react-lazy-images";
import ProductPrice from "./product.price";

interface ProductCardComponentProps {
    product: ProductInterface;
}

const ProductCardComponent: React.FC<ProductCardComponentProps> = (props) => {
    const { i18n } = useTranslation();

    const { product } = props;

    const url = product.slug
        ? "/" + product.slug
        : "/" + product.auto_slug
        ? "/products/" + product.auto_slug
        : "/products/" + product.id;

    return (
        <Link
            to={url}
            className="group col-span-1 min-h-full focus:border-gray-200"
        >
            <div className="w-full rounded bg-white shadow rounded-md h-full cursor-pointer hover:bg-gray-50 group-focus:bg-gray-50 transition duration-150">
                <div className="w-full inline-flex justify-center content-center h-48">
                    <LazyImage
                        src={(product.images && product.images[0].url) || ""}
                        alt=""
                        debounceDurationMs={500}
                        placeholder={({ imageProps, ref }) => (
                            <div className="animate-pulse w-full" ref={ref}>
                                <div className="bg-gray-200 w-full h-48" />
                            </div>
                        )}
                        actual={({ imageProps }) => (
                            <div
                                className="bg-contain bg-no-repeat bg-center"
                                style={{
                                    backgroundImage: `url(${imageProps.src})`,
                                }}
                            >
                                <img
                                    src={imageProps.src}
                                    alt={translationHelper(
                                        product.title,
                                        i18n.language
                                    )}
                                    className="invisible"
                                />
                            </div>
                        )}
                    />
                </div>
                <div className="px-6 py-4">
                    <div className="flex justify-between">
                        <div
                            className="font-bold text-sm mb-2 title-text"
                            title={translationHelper(
                                product.title,
                                i18n.language
                            )}
                        >
                            {translationHelper(product.title, i18n.language)}
                        </div>
                        <div className="font-bold text-sm mb-2 text-primary-600">
                            <ProductPrice product={product} />
                        </div>
                    </div>
                    <p className="text-gray-700 text-sm description-text">
                        {translationHelper(product.short_info, i18n.language)}
                    </p>
                </div>
            </div>
        </Link>
    );
};

export default ProductCardComponent;
