import ProductListingWidget from "./product-listing.widget";
import EventListingWithSearchWidget from "./event-listing-with-search.widget";
import EventListingWidget from "./event-listing.widget";
import ProductListingWithSearchWidget from "widgets/product-listing-with-search.widget";
import SpacerWidget from "widgets/spacer.widget";
import TitleWidget from "widgets/title.widget";
import ImageWidget from "widgets/image.widget";
import ProductListingWithFoldersWidget from "widgets/product-listing-with-folders.widget";
import ProductShowcaseWidget from "widgets/product-showcase.widget";
import EventFolderListingWidget from "widgets/event-folder-listing.widget";
import CardWidget from "widgets/card.widget";
import MatchStatus from "widgets/event-page/match-status.widget";
import PollWidget from "widgets/event-page/poll.widget";
import FeedWidget from "widgets/event-page/feed.widget";
import OfferWidget from "widgets/event-page/offer.widget";
import TimelineWidget from "widgets/event-page/timeline.widget";
import LiveImageWidget from "widgets/event-page/live-image.widget";
import MoveToLiveWidget from "widgets/move-to-live.widget";
import FastProductOrderComponent from "widgets/fast-product-order.component";
import WrapperWidget from "widgets/wrapper.widget";
import RedirectWidget from "widgets/redirect.widget";
import CampaignCodeWidget from "./campaign-code.widget";
import EventShowcaseWidget from "./event-showcase.widget";
import StreamWidget from "./event-page/stream.widget";
import VideoWidget from "./video.widget";
import PollResultsWidget from "./event-page/poll-results.widget";
import PostToEventFeedWidget from "./event-page/post-to-feed.widget";

export interface WidgetInterface {
    type: keyof typeof Widgets;
    id?: string;
    data?: any;
    page?: string;
}

export const Widgets = {
    "common:card": CardWidget,
    "common:spacer": SpacerWidget,
    "common:title": TitleWidget,
    "v0:event_listing": EventListingWidget,
    "v0:event_listing_with_search": EventListingWithSearchWidget,
    "v0:product_listing": ProductListingWidget,
    "v0:product_listing_with_search": ProductListingWithSearchWidget,
    "v0:product_listing_with_folders": ProductListingWithFoldersWidget,
    "v0:image": ImageWidget,
    "v1:product_showcase": ProductShowcaseWidget,
    "v1:event_showcase": EventShowcaseWidget,
    "v0:event_folders_listing": EventFolderListingWidget,
    "v1:match_status": MatchStatus,
    "v1:event_poll": PollWidget,
    "v1:event_feed": FeedWidget,
    "v1:post_to_event_feed": PostToEventFeedWidget,
    "v1:event_offer": OfferWidget,
    "v1:timeline": TimelineWidget,
    "v1:image": LiveImageWidget,
    "v1:move_to_live": MoveToLiveWidget,
    "v1:fast_product_order": FastProductOrderComponent,
    "v1:wrapper": WrapperWidget,
    "v1:redirect": RedirectWidget,
    "v1:campaign_code": CampaignCodeWidget,
    "v1:stream": StreamWidget,
    "v1:poll_results": PollResultsWidget,
    "v1:video": VideoWidget,
};
