import React from "react";

export interface MatchStatusWidgetProps {
    timer: number;
    started_at: number;
    home_name: string;
    home_score: number;
    visitor_name: string;
    visitor_score: number;
}

const MatchStatus: React.FC<MatchStatusWidgetProps> = (props) => {
    const { home_name, home_score, visitor_name, visitor_score, timer } = props;

    return (
        <div className="bg-white shadow overflow-hidden rounded-md overflow-hidden md:flex col-span-6 my-2">
            <div className="flex flex-wrap w-full p-5">
                <div className="w-1/3 flex flex-wrap content-center justify-center">
                    <div className="text-center">
                        <p className="text-gray-700 text-lg font-bold pt-1">
                            {home_score}
                        </p>
                        <p className="font-bold text-sm text-gray-600">
                            {home_name}
                        </p>
                    </div>
                </div>
                <div className="w-1/3 flex flex-wrap content-center justify-center">
                    <div className="text-center">
                        <p className="text-gray-700 text-lg font-bold pt-1">
                            -
                        </p>
                        <p className="font-bold text-sm text-gray-600">
                            {timer}
                        </p>
                    </div>
                </div>
                <div className="w-1/3 h-100 flex flex-wrap content-center justify-center">
                    <div className="text-center">
                        <p className="text-gray-700 text-lg font-bold pt-1">
                            {visitor_score}
                        </p>
                        <p className="font-bold text-sm text-gray-600">
                            {visitor_name}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MatchStatus;
