import React, { useEffect } from "react";

interface RedirectWidgetProps {
    url: string;
}

const RedirectWidget: React.FC<RedirectWidgetProps> = (props) => {
    const { url } = props;

    useEffect(() => {
        window.location.href = url;
    }, [url]);

    return <p>Uudelleenohjataan...</p>;
};

export default RedirectWidget;
