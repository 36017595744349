import { useTranslation } from "react-i18next";
import React from "react";
import { translationHelper } from "helpers/translation.helper";
import { FolderInterface } from "interfaces/folder.interface";
import { Link } from "gatsby";

interface EventFolderCardProps {
    folder: FolderInterface;
}

const EventFolderCard = (props: EventFolderCardProps) => {

    const { i18n } = useTranslation();
    const { folder } = props;

    const url = folder.slug ? "/" + folder.slug : "";

    return (
        <div className="min-h-full col-span-4 md:col-span-2">
            <Link
                to={url}
                className="bg-white shadow rounded-md overflow-hidden h-full cursor-pointer"
            >
                {folder.main_image_url && (
                    <div
                        className="bg-contain bg-no-repeat bg-center"
                        style={{
                            backgroundImage: `url(${folder.main_image_url})`,
                        }}
                    >
                        <img
                            src={folder.main_image_url}
                            alt=""
                            className="invisible"
                        />
                    </div>
                )}
                <div className="px-6 py-4">
                    <div
                        className="font-bold text-sm mb-0 title-text"
                        title={translationHelper(folder.title, i18n.language)}
                    >
                        {translationHelper(folder.title, i18n.language)}
                    </div>
                    <p className="text-gray-700 text-sm description-text">
                        {translationHelper(folder.description, i18n.language)}
                    </p>
                </div>
            </Link>
        </div>
    );
};

export default EventFolderCard;
