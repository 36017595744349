import React from "react";

interface TitleWidgetProps {
    text: string;
}

const TitleWidget: React.FC<TitleWidgetProps> = (props) => (
    <p className="mb-1 text-gray-600 text-base font-bold">{props.text}</p>
);

export default TitleWidget;
