import React from "react";
import { AppState } from "store/reducers";
import { connect } from "react-redux";
import { ProductInterface } from "interfaces/product.interface";
import FastOrderClickButton from "widgets/fast-order-click-button.widget";

interface OfferWidgetProps {
    title: string;
    text: string;
    button_label?: string;
    product?: string;
    fast_order_product?: ProductInterface;
    show?: string;
}

const OfferWidget: React.FC<OfferWidgetProps> = (props) => {
    const { title, text, button_label, fast_order_product, show } = props;

    return show !== "false" ? (
        <div className="bg-white shadow rounded-md col-span-6 my-2">
            <div className="px-4 py-5 sm:p-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                    {title}
                </h3>
                <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                    <div className="max-w-xl text-sm leading-5 text-gray-500">
                        <p className="whitespace-pre-line">{text}</p>
                    </div>
                    {button_label && fast_order_product && (
                        <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                            <FastOrderClickButton
                                label={button_label}
                                product={fast_order_product}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    ) : (
        <span />
    );
};

const mapStateToProps = (state: AppState, props: OfferWidgetProps) => ({
    fast_order_product: state.product.products.find(
        (product) => product.id === props.product
    ),
});

export default connect(mapStateToProps)(OfferWidget);
