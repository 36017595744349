import React from "react";
import { connect } from "react-redux";
import { ProductInterface } from "interfaces/product.interface";
import ProductCard from "components/products/product-card.component";
import { AppState } from "store/reducers";

interface ProductShowcaseWidgetProps {
    product_ids: string[];
    products: ProductInterface[];
}

const ProductShowcaseWidget: React.FC<ProductShowcaseWidgetProps> = (props) => {
    return (
        props.products && (
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-4 px-4 py-4 sm:p-0">
                {props.products.map((product) => (
                    <ProductCard product={product} key={product.id} />
                ))}
            </div>
        )
    );
};

const mapStateToProps = (
    state: AppState,
    ownProps: ProductShowcaseWidgetProps
) => ({
    products: state.product.products.filter((product) =>
        ownProps.product_ids.includes(product.id)
    ),
});

export default connect(mapStateToProps)(ProductShowcaseWidget);
