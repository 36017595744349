import React, { useEffect } from "react";
import ReactPlayer from "react-player";
import { freezeLive } from "../../store/actions/live.action";
import io from "socket.io-client";
import { useAppDispatch } from "../../hooks/use-redux.hook";

interface StreamWidgetProps {
    show: string;
    eventid: string;
}

const StreamWidget: React.FC<StreamWidgetProps> = (props) => {
    const dispatch = useAppDispatch();

    const [isStreamEnabled, setStreamEnabled] = React.useState(false);
    const [streamUrl, setStreamUrl] = React.useState("");

    const [isStreamPlaying, setStreamPlaying] = React.useState(false);
    const [
        isStreamReachedIdleStatus,
        setStreamReachedIdleStatus,
    ] = React.useState(false);

    const onPlaybackStart = () => {
        dispatch(freezeLive(true));
        setStreamPlaying(true);
    };
    const onPlaybackEnd = () => {
        dispatch(freezeLive(false));
        setStreamPlaying(false);
    };

    useEffect(() => {
        console.log(isStreamReachedIdleStatus, isStreamPlaying);
        if (isStreamReachedIdleStatus && !isStreamPlaying) {
            setStreamEnabled(false);
            setStreamUrl("");
            dispatch(freezeLive(false));
        }
    }, [isStreamPlaying, isStreamReachedIdleStatus]);

    useEffect(() => {
        const socket = io(process.env.GATSBY_LIVE_API_URL || "");
        socket.on("connect", () => {
            console.log("Connected stream listener");
            socket.emit("join", { event: props.eventid }, () => {
                console.log("Joined livestream");
            });
        });

        socket.on("live_stream.active", (data: { playback_url: string }) => {
            console.log("Stream is streaming...");
            dispatch(freezeLive(true));
            setStreamEnabled(true);
            setStreamUrl(data.playback_url);
        });

        socket.on("live_stream.idle", () => {
            console.log("Stream reached idle status.");
            setStreamReachedIdleStatus(true);
        });

        return () => {
            dispatch(freezeLive(false));
            socket.disconnect();
            console.log("Disconnected from live stream");
        };
    }, []);

    return props.show === "true" && isStreamEnabled ? (
        <div className="col-span-6 my-2">
            <div className="aspect-w-16 aspect-h-9">
                <ReactPlayer
                    url={streamUrl}
                    autoPlay={true}
                    controls={true}
                    width="100%"
                    height="100%"
                    onPlay={onPlaybackStart}
                    onPause={onPlaybackEnd}
                    onEnded={onPlaybackEnd}
                />
            </div>
        </div>
    ) : (
        <span />
    );
};

export default StreamWidget;
