import React from "react";

interface ImageWidgetProps {
    src: string;
    alt: string;
}

const ImageWidget: React.FC<ImageWidgetProps> = (props) => (
    <img src={props.src} alt={props.alt} className="w-full" />
);

export default ImageWidget;
