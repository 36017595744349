import React from "react";
import { connect } from "react-redux";
import { ProductInterface, ProductType } from "interfaces/product.interface";
import { AppState } from "store/reducers";
import ProductListingWidget from "widgets/product-listing.widget";

interface ProductListingWithFoldersWidgetProps {
    folders: string[];
    products?: ProductInterface[];
}

const ProductListingWithFoldersWidget: React.FC<ProductListingWithFoldersWidgetProps> = (
    props
) => {
    return props.products ? (
        <ProductListingWidget products={props.products} />
    ) : (
        <span />
    );
};

const mapStateToProps = (
    state: AppState,
    props: ProductListingWithFoldersWidgetProps
) => {
    const products = filterProducts(
        props.products ? props.products : state.product.products
    );
    return {
        products:
            products.filter((product) =>
                product.folder_ids?.some(
                    (folder) => props.folders.indexOf(folder) !== -1
                )
            ) || [],
    };
};

const filterProducts = (products: ProductInterface[]) =>
    products.filter((product) =>
        [
            ProductType.MERCHANDISE,
            ProductType.MEMBERSHIP,
            ProductType.VALUE_VOUCHER,
            ProductType.SERIAL_CARD,
        ].includes(product.type)
    );

export default connect(mapStateToProps)(ProductListingWithFoldersWidget);
