import React from "react";
import SearchBar, { SearchBarFolder } from "components/utils/SearchBar";
import EventListingWidget from "./event-listing.widget";
import { SortType } from "interfaces/sort-type.enum";
import { EventInterface } from "interfaces/event.interface";
import { useAppSelector } from "../hooks/use-redux.hook";

interface EventListingWithSearchWidgetProps {
}

const EventListingWithSearchWidget: React.FC<EventListingWithSearchWidgetProps> = () => {
    const event = useAppSelector(state => state.event);

    const [filterData, setFilterData] = React.useState<EventInterface[]>([]);

    React.useEffect(() => {
        if (event.isLoaded && !event.isEmpty) {
            setFilterData(event.events);
        }
    }, [event]);

    return (
        <div>
            <div className="px-4 pt-2 sm:p-0">
                <SearchBar
                    data={event.events}
                    filter={setFilterData}
                    available_sorts={[
                        {
                            label: "Osuvimmat",
                            type: SortType.CLOSEST_TO_SEARCH,
                        },
                        {
                            label: "Nouseva päivämäärä",
                            type: SortType.DATE_ASC,
                        },
                        {
                            label: "Laskeva päivämäärä",
                            type: SortType.DATE_DESC,
                        },
                    ]}
                    folders={SearchBarFolder.EVENTS}
                />
            </div>
            <EventListingWidget events={filterData} />
        </div>
    );
};

export default EventListingWithSearchWidget;
