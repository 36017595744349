import React, { useEffect } from "react";
import { PageInterface } from "../interfaces/page.interface";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { WidgetInterface, Widgets } from "widgets";
import { translationHelper } from "../helpers/translation.helper";
import Layout from "./layout.component";
import { useConfig } from "../hooks/use-config.hook";
import { useLocation } from "@reach/router";

interface PageProps {
    pageContext: {
        page: PageInterface;
    };
}

const Page: React.FC<PageProps> = (props) => {
    const { i18n } = useTranslation();
    const { pageContext } = props;

    const config = useConfig();

    const location = useLocation();

    useEffect(() => {
        if (location.hash.startsWith("#eventiocom_admin_token")) {
            location.hash = "";
        }
    }, [location.hash]);

    if (!pageContext.page.widgets) return null;

    const WidgetBuilder = (props: WidgetInterface) => {
        const Widget = Widgets[props.type];
        return Widget ? (
            <Widget {...props.data} />
        ) : (
            <p>Virhe tapahtui komponenttia ladatessa! (Virhekoodi: COMP-1)</p>
        );
    };

    const widgets: any = [];

    const buildPage = () => {
        pageContext.page.widgets.map((widget, i) =>
            widgets.push(
                <React.Fragment key={`${widget.id}_${i}`}>
                    <WidgetBuilder type={widget.type} data={widget.data} />
                    <div className="mb-4" />
                </React.Fragment>
            )
        );
    };

    buildPage();

    return (
        <Layout>
            <Helmet>
                <title>
                    {translationHelper(pageContext.page.title, i18n.language)} -{" "}
                    {translationHelper(config.title, i18n.language)}
                </title>
                <link
                    rel="icon"
                    sizes="48x48"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-48x48.png`}
                />
                <link
                    rel="icon"
                    sizes="72x72"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-72x72.png`}
                />
                <link
                    rel="icon"
                    sizes="96x96"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-96x96.png`}
                />
                <link
                    rel="icon"
                    sizes="144x144"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-144x144.png`}
                />
                <link
                    rel="icon"
                    sizes="192x192"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-192x192.png`}
                />
                <link
                    rel="icon"
                    sizes="256x256"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-256x256.png`}
                />
                <link
                    rel="icon"
                    sizes="384x384"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-384x384.png`}
                />
                <link
                    rel="icon"
                    sizes="512x512"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-512x512.png`}
                />
            </Helmet>
            {widgets}
        </Layout>
    );
};
export default Page;
