import React, { useEffect } from "react";
import axios from "axios";
import {
    Bar,
    BarChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
} from "recharts";

interface PollResultsWidgetProps {
    type: string;
    poll_id: string;
    show: string;
}

const PollResultsWidget: React.FC<PollResultsWidgetProps> = (props) => {
    const [results, setResults] = React.useState<
        { value: string; answer_count: number; percentage: number }[]
    >([]);

    const [title, setTitle] = React.useState("");

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const result = await axios.get<
                    {
                        value: string;
                        answer_count: number;
                        percentage: number;
                    }[]
                >(
                    `${process.env.GATSBY_LIVE_API_URL}/poll/${props.poll_id}/stats`
                );

                result.data.forEach((res) => {
                    if (res.value === "up") {
                        res.value = `👍 - ${res.percentage.toPrecision(2)}%`;
                    }
                    if (res.value === "down") {
                        res.value = `👎 - ${res.percentage.toPrecision(2)}%`;
                    }
                });

                setResults(result.data);
            } catch (error) {
                console.log("Error occurred during poll stats fetching", error);
            }
        };

        const fetchPollDetails = async () => {
            try {
                const result = await axios.get<{
                    id: string;
                    type: string;
                    title: string;
                    values: string;
                }>(`${process.env.GATSBY_LIVE_API_URL}/poll/${props.poll_id}`);
                setTitle(result.data.title);
                console.log(result.data);
            } catch (error) {
                console.log("Error occurred during poll info fetching", error);
            }
        };

        fetchPollDetails();
        fetchStats();

        const checkLoop = setInterval(async () => {
            await fetchStats();
        }, 10 * 1000);

        return () => {
            clearInterval(checkLoop);
        };
    }, []);

    let chart = <span />;

    if (props.type === "bar") {
        chart = (
            <div className="h-64">
                <ResponsiveContainer className="">
                    <BarChart
                        width={500}
                        height={300}
                        data={results}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="value" />
                        <Tooltip />
                        <Bar
                            dataKey="answer_count"
                            fill="#8884d8"
                            name="Vastausmäärä"
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }

    if (props.type === "list") {
        chart = (
            <ul className="divide-y divide-gray-200">
                {results.map((result, index) => (
                    <li
                        className="py-4 flex space-x-3"
                        key={`poll_result_${props.poll_id}_${index}`}
                    >
                        <div className="flex flex-col">
                            <span className="text-sm leading-5 font-medium text-gray-900 pl-6">
                                {result.value}
                            </span>
                        </div>
                    </li>
                ))}
            </ul>
        );
    }

    return props.show !== "false" ? (
        <div className="bg-white shadow rounded-md col-span-6 my-2">
            <div className="w-full flex items-center px-4 border-b border-gray-200 sm:px-6 py-6 leading-6 font-medium text-gray-900">
                Tulokset: {title}
            </div>
            <div className=" w-full">{chart}</div>
        </div>
    ) : (
        <span>{props.type}</span>
    );
};

export default PollResultsWidget;
