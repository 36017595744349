import { useTranslation } from "react-i18next";
import React from "react";
import { EventInterface } from "interfaces/event.interface";
import moment from "moment";
import { translationHelper } from "helpers/translation.helper";
import { Link } from "gatsby";
import { LazyImage } from "react-lazy-images";

interface EventCardProps {
    event: EventInterface;
}

const EventCard: React.FC<EventCardProps> = (props) => {
    const { i18n } = useTranslation();

    const { event } = props;

    const date_info = event.date_info
        ? translationHelper(event.date_info, i18n.language)
        : moment(event.start_date).format(" DD.MM. HH:mm");

    const location_output = event.location_info
        ? event.location_info
        : event.location
        ? event.location.title
        : false;

    return (
        <Link
            to={"/events/" + event.id}
            className="col-span-3 md:col-span-2 lg:col-span-1 min-h-full group"
        >
            <div className="w-full rounded-md bg-white overflow-hidden shadow h-full cursor-pointer hover:bg-gray-50 group-focus:bg-gray-50">
                <div className="w-full flex flex-wrap justify-center content-center">
                    {event.images && event.images?.length > 0 && (
                        <LazyImage
                            src={event.images[0].url}
                            alt=""
                            debounceDurationMs={500}
                            placeholder={({ imageProps, ref }) => (
                                <div className="animate-pulse w-full" ref={ref}>
                                    <div className="bg-gray-200 w-full h-48" />
                                </div>
                            )}
                            actual={({ imageProps }) => (
                                <div
                                    className="bg-contain bg-no-repeat bg-center"
                                    style={{
                                        backgroundImage: `url(${imageProps.src})`,
                                    }}
                                >
                                    <img
                                        src={imageProps.src}
                                        alt={translationHelper(
                                            event.title,
                                            i18n.language
                                        )}
                                        className="invisible"
                                    />
                                </div>
                            )}
                        />
                    )}
                </div>
                <div className="px-6 py-4">
                    <div
                        className="font-bold text-sm mb-0 title-text"
                        title={translationHelper(event.title, i18n.language)}
                    >
                        {translationHelper(event.title, i18n.language)}
                    </div>
                    <div className="flex flex-col">
                        <div className="font-bold text-sm mb-0 text-primary-600">
                            {date_info}
                        </div>
                        <div className="text-sm font-semibold text-gray-700 mb-2">
                            {location_output &&
                                translationHelper(
                                    location_output,
                                    i18n.language
                                )}
                        </div>
                    </div>
                    <p className="text-gray-700 text-sm description-text">
                        <span>
                            {translationHelper(
                                event.description,
                                i18n.language
                            )}
                        </span>
                    </p>
                    <p className="text-gray-700 text-sm">
                        {event.availability.available ? null : (
                            <>
                                <span className="font-semibold">
                                    {translationHelper(
                                        event.availability.info,
                                        i18n.language
                                    )}
                                </span>
                            </>
                        )}
                    </p>
                </div>
            </div>
        </Link>
    );
};

export default EventCard;
