import React from "react";

interface TimelineWidgetProps {
    time: string;
    title: string;
    text: string;
    show_line: string | boolean;
    show: string | boolean;
}

const TimelineWidget: React.FC<TimelineWidgetProps> = (props) => {
    const { time, title, text, show_line, show } = props;

    return show === "true" || show === true ? (
        <div className="grid grid-cols-1 col-span-6">
            <div className="w-full bg-white shadow rounded-md px-4 py-5 sm:px-6 rounded-md col-span-1">
                <h3 className="leading-6 font-medium text-gray-900">
                    <span className="inline-flex justify-between w-full">
                        <span>{title}</span>
                        <span className="text-gray-500 text-sm">{time}</span>
                    </span>
                </h3>
                <p className="whitespace-pre-line">{text}</p>
            </div>
            {(show_line === "true" || show_line === true) && (
                <div className="h-5 border-l-4 border-primary-400 ml-10 col-span-1" />
            )}
        </div>
    ) : (
        <span />
    );
};

export default TimelineWidget;
