import React from "react";
import { connect } from "react-redux";
import EventCard from "components/events/event-card.component";
import { EventInterface } from "interfaces/event.interface";
import { AppState } from "store/reducers";

interface EventShowcaseWidgetProps {
    events: EventInterface[];
    event_ids?: string[];
    isLoaded?: boolean;
    isEmpty?: boolean;
}

const EventShowcaseWidget: React.FC<EventShowcaseWidgetProps> = (props) => {
    return (
        props.events && (
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 px-4 py-4 sm:p-0">
                {props.events.map((event: EventInterface) => (
                    <EventCard event={event} key={event.id} />
                ))}
            </div>
        )
    );
};

const mapStateToProps = (
    state: AppState,
    ownProps: EventShowcaseWidgetProps
) => ({
    events: state.event.events.filter((event) =>
        ownProps.event_ids?.includes(event.id)
    ),
    isLoaded: state.event.isLoaded,
    isEmpty: state.event.isEmpty,
});

export default connect(mapStateToProps)(EventShowcaseWidget);
