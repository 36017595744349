import React, { FormEvent, useEffect } from "react";
import axios, { AxiosResponse } from "axios";

interface PostToEventFeedWidgetProps {
    widget_title?: string;
    widget_pretext?: string;
    widget_posttext?: string;
    widget_sent_text?: string;
    widget_button_text?: string;
    eventid: string;
    message_status: string;
    token?: string;
    show: string;
}

const sendNewPostToApi = async (
    eventId: string,
    message: string,
    messageStatus: string,
    token?: string
): Promise<AxiosResponse> => {
    return await axios.post(
        `${process.env.GATSBY_LIVE_API_URL}/live-feed/${eventId}/posts`,
        {
            post: {
                title: "",
                text: message,
                status: messageStatus,
            },
        }
    );
};

const PostToEventFeedWidget: React.FC<PostToEventFeedWidgetProps> = (props) => {
    const [text, setText] = React.useState<string>("");
    const [error, setError] = React.useState<string | null>(null);
    const [isSubmitDisabled, setSubmitDisabled] = React.useState<boolean>(true);
    const [isSubmitting, setSubmitting] = React.useState<boolean>(false);
    const [isMessageSent, setMessageSent] = React.useState<boolean>(false);

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setSubmitting(true);
        try {
            const response = await sendNewPostToApi(
                props.eventid,
                text,
                props.message_status,
                props.token
            );
            const data = response.data;
            setText("");
            setMessageSent(true);
            const timer = setTimeout(() => {
                setMessageSent(false);
            }, 5 * 1000);
        } catch (error) {
            console.error(error);
            setError(
                "Päivitystä ei voitu lähettää virheen vuoksi. Yritä uudelleen."
            );
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        if (!text || isSubmitting) {
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }
    }, [text, isSubmitting]);

    return props.show === "true" ? (
        <div className="bg-white shadow rounded-md col-span-6 my-2">
            <div className="bg-white px-4 py-6 rounded-md shadow">
                {props.widget_title && (
                    <div className="mb-4">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            {props.widget_title}
                        </h3>
                    </div>
                )}
                {isMessageSent ? (
                    <div
                        className="bg-green-100 border-t border-b border-green-500 text-green-700 px-4 py-3"
                        role="alert"
                    >
                        <p className="font-bold">
                            {props.widget_sent_text || "Viestisi on lähetetty"}
                        </p>
                    </div>
                ) : (
                    <form className="space-y-2" onSubmit={handleSubmit}>
                        {props.widget_pretext && (
                            <div className="max-w-xl text-sm leading-5 text-gray-500">
                                <p className="whitespace-pre-line">
                                    {props.widget_pretext}
                                </p>
                            </div>
                        )}
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <textarea
                                id="text"
                                className="form-input block w-full sm:text-sm sm:leading-5"
                                value={text || ""}
                                onChange={(event) => {
                                    setText(event.target.value);
                                    setError(null);
                                }}
                            />
                        </div>
                        {error ? (
                            <div className="bg-red-100">{error}</div>
                        ) : (
                            <span />
                        )}

                        {props.widget_posttext && (
                            <div className="max-w-xl text-xs leading-5 text-gray-300">
                                <p className="whitespace-pre-line">
                                    {props.widget_posttext}
                                </p>
                            </div>
                        )}

                        <div className="mt-8 pt-5">
                            <div className="flex justify-end">
                                <span className="ml-3 inline-flex rounded-md shadow-sm">
                                    <button
                                        disabled={isSubmitDisabled}
                                        type="submit"
                                        className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                                    >
                                        {props.widget_button_text || "Lähetä"}
                                    </button>
                                </span>
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </div>
    ) : (
        <span />
    );
};

export default PostToEventFeedWidget;
